<template>
	<div class="teamMembers_block">
		<section class="header-bg" style="">
		</section>
		<div class="se111">
			<div class="se1">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Researchers
					</strong></p>
			</div>
		</div>

		<!-- 团队 -->

		<div style="display: flex;justify-content: center;">
			<div class="teamM">
				<div class="team_content">
					<div v-for="(item, index) in teamArr" :key="index" class="row">
						<div class="card">
							<div class="card_image_box">
								<img v-if="item.img" :src="item.img" :alt="item.authorName">

								<div class="card_text">
									<h3 v-if="mod">{{ item.authorName }}</h3>
									<p v-if="mod" style="color: #000;font-weight: bold;">{{ item.position || 'No Data.'
										}}
									</p>
								</div>
							</div>
							<div class="card_desc_box">
								<h3 v-if="!mod">{{ item.authorName }}</h3>
								<p>{{ item.affiliation }}</p>
								<p v-if="!mod" style="color: #000;font-weight: bold;">{{ item.position || 'No Data.' }}
								</p>
								<p v-if="item.email"><a :href="'mailto:' + item.email + '?subject=&body='">{{ item.email
										}}</a></p>
								<div class="btn_group">
									<el-button type="primary" size="small" @click="goToBlank(item.profile)"
										v-if="item.profile">View CV</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
export default {
	name: "teamMembers",
	data() {
		return {
			mod: false,
			teamArr: [{
				authorName: 'PROF. DR. Noorhidawati binti Abdullah',
				affiliation: 'Department of Library and Information Science, \nFaculty of Arts and Social Sciences, \nUniversity of Malaya, \nKuala Lumpur, \nMalaysia',
				img: require('@/assets/images/team/Abdullah.jpg'), // 使用 require 进行引入
				profile: 'https://umexpert.um.edu.my/noorhidawati.html',
				position: 'Researcher',
				email: ""
			},
			{
				authorName: 'Asssociate PROF. DR. Yanti Idaya Aspura binti Mohd Khalid',
				affiliation: 'Department of Library and Information Science, \nFaculty of Arts and Social Sciences, \nUniversity of Malaya, \nKuala Lumpur, \nMalaysia',
				img: require('@/assets/images/team/Yanti.jpg'), // 同样使用 require
				profile: 'https://umexpert.um.edu.my/yanti.html',
				position: 'Researcher',
				email: ""
			},
			{
				authorName: 'PROF. Muhammad Aqeel Ashraf',
				affiliation: 'Zebiline International Publishing, \nC2-2-3,Block 2, CBD Perdana 3, \nLingkaran Cyber Point Timur, \nCyber 12,63000 Cyberjaya, \nSelangor, \nMalaysia',
				img: require('@/assets/images/team/Ashraf.jpg'), // 同样处理
				profile: '',
				position: 'Senior Consultant',
				email: "asharf@contrimetric.com"
			},
			{
				authorName: 'Dr.Amin D. Malayeri',
				affiliation: 'Doctor of Business Administration, \nPost-master of Business Administration in Data Science, \nMBA in Information Technology Management',
				img: require('@/assets/images/team/Amin.png'),
				profile: '',
				position: 'Senior Consultant',
				email: "amin.daneshmand@contrimetric.com"
			},
			]
		};
	},
	watch: {},
	created() {

	},
	mounted() {
		this.updatePagerCount();
		window.addEventListener('resize', this.updatePagerCount);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updatePagerCount);
	},
	methods: {
		goToBlank(url) {
			window.open(url, '_blank')
		},
		updatePagerCount() {
			const width = window.innerWidth;
			this.mod = width < 768;
		},
	},
}
</script>

<style scoped lang="scss">
@import "../assets/css/minix.scss";

.teamMembers_block {
	width: 100%;

	.se111 {
		width: 100%;
		margin-top: 40px;
		display: flex;
		justify-content: center;

		.se1 {
			@include responsive-width(1200px);
			height: 20px;
		}
	}
}

.teamM {
	margin-top: 80px;
	@include responsive-width(1200px);
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;

	.team_content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
}

.row {
	width: 50%;
	margin-bottom: 30px;
}

.card {
	position: relative;
	box-sizing: border-box;
	width: 90%;
	height: 260px;
	display: flex;
	padding: 20px;
	padding-bottom: 30px;
	background-color: #edfeff;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	.btn_group {
		position: absolute;
		right: 20px;
		bottom: -10px;
		text-align: right;
		margin-bottom: 20px;
		transform: translateY(-12px);
	}
}

.card img {
	width: 130px;
	height: 170px;
	object-fit: cover;
	min-height: inherit;
	margin-right: 20px;
}

.card h3 {
	font-size: 18px;
}

.card p {
	white-space: break-spaces;
	line-height: 1.6;
	margin-top: 6px;
	font-size: 14px;
	color: #666;
}

@media (max-width: 768px) {
	.team_content {
		flex-direction: column;
	}

	.teamM {
		padding: 0;
		margin-top: 50px;
	}

	.row {
		width: 100%;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;

		.card {
			height: 100%;
			border-radius: 0;
			padding: 30px 20px;
			flex-direction: column;

			p {
				margin-top: 10px;
			}

			.card_image_box {
				display: flex;
				align-items: center;
			}

			img {
				width: 78px;
				height: 102px;
			}
		}
	}
}
</style>